import requests from '@/api/request.js'
// 获取部门字典
const getDept = async () => {
    return await requests({
        method: 'post',
        url: 'admin/getDeptList'
    })
}

// 分页删除优化
const optimize = function (prefix = null) {
    let total = ''
    let pagesize = ''
    let page = ''
    if (prefix) {
        total = prefix + '_total'
        this[total] -= 1
        pagesize = prefix + '_pagesize'
        page = prefix + '_page'
    } else {
        total = 'total'
        this[total] -= 1
        pagesize = 'pagesize'
        page = 'page'
    }
    // 如果在最后一页删除操作
    if (this[page] === Math.ceil((this[total] + 1) / this[pagesize])) {
        this[page] = Math.ceil(this[total] / this[pagesize])
    }
}


export { getDept, optimize }

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { isMobile } from './utils/tool'


import './plugins/element.js'
// 导入全局样式表
import './assets/css/global.css'
import '@/utils/rem.js'
import { limitflow, notshake, delTemplate } from '@/utils/tool.js'
import { optimize } from './utils/common'


Vue.use(PiniaVuePlugin)

// 鉴定是否为移动端
if (isMobile()) {
  Vue.prototype.$MOBILE = true
} else {
  Vue.prototype.$MOBILE = false
}
const pinia = createPinia()
Vue.config.productionTip = false
new Vue({
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')
// 优化分页
Vue.prototype.$optimize = optimize
Vue.prototype.$limitflow = limitflow
Vue.prototype.$notshake = notshake
Vue.prototype.$delTemplate = delTemplate
Vue.prototype.$VUE_APP_URL = process.env.VUE_APP_URL
Vue.prototype.$MAX = 9999999


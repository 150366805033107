// 二次封装axios
import axios from 'axios'
import CryptoJS from 'crypto-js'
import Vue from 'vue'
import router from '@/router'
import { entriesEncode, fullurlEncode, delSmt } from '@/utils/tool'
// const { CancelToken, isCancel } = axios
// var source = null
console.log(process.env.VUE_APP_URL);
let loading
// const baseurl = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_URL
// 1：利用axios对象的方法create，去创建一个axios实例
// 2.requests 就是axios，配置一下用起来更顺手
const requests = axios.create({
  // 基础路径
  withCredentials: true,
  baseURL: '/api',
  // 代表请求超时的的时间 1h
  timeout: 3600000,
  retry: 3, //设置全局重试请求次数（最多重试几次请求）
  retryDelay: 1500 //设置全局请求间隔
})
// 请求拦截器：在请求之前做一些事情
requests.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = "multipart/form-data"
  } else {
    config.data = delSmt(config.data)
  }
  // 如果是仓库部接口,则将参数的所有字符串urlencode编码
  // else if (/\/warehouse/.test(config.url)) {
  // config.data = entriesEncode(config.data)
  // }
  // console.log(config);
  // 在仓库部将timeout值变小
  // const isStore = /(.*)warehouse(.*)/.test(config.url)
  // isStore ? config.timeout = 1000 : 0
  // source = CancelToken.source()
  // config.CancelToken = source.token
  // 访问相同地址的请求会取消上一个请求
  // config:配置对象，其中有一个属性很重要，headers请求头
  // config.headers['Cache-Control'] = 'public'
  config.headers.token = window.localStorage.getItem('token')
  // config.headers.header("Access-Control-Allow-Origin", "*");
  // 时间戳
  config.headers.timestamp = new Date().getTime()
  // 唯一字段
  config.headers.nonce = Math.random().toString(36).substring(2)

  for (const a in config.data) {
    if (config.data[a] instanceof Array) {
      config.data[a] = JSON.stringify(config.data[a])
    }
  }
  // 签名
  let str
  let data


  if (!config.headers.token) {
    if (!config.data) {
      str = ''
    } else {
      str = Object.keys(config.data).map(key => `${key}=${fullurlEncode(config.data[key])}`).join('&')
    }
    data = `timestamp=${config.headers.timestamp}&nonce=${config.headers.nonce}&token=${str ? '&' + str : ''}`
  } else {
    // get 方法
    if (config.method == 'get') {
      console.log(config);
      let strs = config.url.split('?')[1]
      if (strs) {
        str = strs.split('&').join('&')
        data = `timestamp=${config.headers.timestamp}&nonce=${config.headers.nonce}&token=${config.headers.token}&${str}`
      } else {
        data = `timestamp=${config.headers.timestamp}&nonce=${config.headers.nonce}&token=${config.headers.token}`
      }
    }
    else {
      // post 方法
      if (config.data) {
        if (!(config.data instanceof Object)) {
          config.data = JSON.parse(config.data)
        }
        str = Object.keys(config.data).map(
          key => `${key}=${fullurlEncode(config.data[key])}`
        ).join('&')
        data = `timestamp=${config.headers.timestamp}&nonce=${config.headers.nonce}&token=${config.headers.token}&${str}`
      } else {
        data = `timestamp=${config.headers.timestamp}&nonce=${config.headers.nonce}&token=${config.headers.token}`
      }
    }
  }
  const sortedData = data.split('&') // 先将字符串按 '&' 符号分割成数组
    .sort() // 对数组进行字典排序
    .map(kv => kv.split('=')) // 将每个键值对字符串按 '=' 符号分割成键和值的数组
    .map(([k, v]) => `${k}=${v}`) // 将键和值的数组拼接成新的键值对字符串
    .join('&') // 将所有键值对字符串用 '&' 符号连接起来
  const newSortedData = `${sortedData}&ajsdfjalssmdfkajsdlkj`
  console.log(newSortedData)
  // MD5加密
  const md5Result = CryptoJS.MD5(newSortedData).toString()
  // 字母大写
  const signature = md5Result.toUpperCase()
  config.headers.signature = signature
  // loading遮罩开启
  loading = Vue.prototype.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
    customClass: 'osloading' //自定义类名
  })
  return config
})

// 响应拦截器
requests.interceptors.response.use(
  // 成功的回调函数
  (res) => {
    loading.close()
    const filterData = res.data
    // 登录失效
    if (filterData.code === 202) {
      // console.log(filterData);
      window.localStorage.removeItem('token')
      router.push('/')
    }
    // 提示错误信息
    if (filterData.show) {
      Vue.prototype.$message({
        message: filterData.msg,
        type: 'error'
      })
    }

    // 如果有文件则返回文件名和数据
    if (res.headers['content-disposition']) {
      return {
        data: res.data,
        name: res.headers['content-disposition'].split('=')[1]
      }
    }
    return res.data
  },
  // 失败的回调函数
  (error) => {
    console.log(error);
    const config = error.config
    Vue.prototype.$message.error('网络不稳定，请稍后再试！')
    loading.close()
    return Promise.reject(error)
    if (!config || !config.retry) return Promise.reject(error)
    // __retryCount用来记录当前是第几次发送请求
    config.__retryCount = config.__retryCount || 0
    // 如果当前发送的请求大于等于设置好的请求次数时，不再发送请求，返回最终的错误信息
    if (config.__retryCount >= config.retry) {
      Vue.prototype.$message.error('网络不稳定，请稍后再试！2')
      loading.close()
      return Promise.reject(error)
    }
    // 记录请求次数+1
    config.__retryCount += 1
    // 设置请求间隔 在发送下一次请求之前停留一段时间，时间为上方设置好的请求间隔时间
    const backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve()
      }, config.retryDelay || 1)
    })
    // 再次发送请求
    return backoff.then(function () {
      console.log(config)
      return requests(config)
    })
  })

export default requests

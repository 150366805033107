import Vue from 'vue'
import VueRouter from 'vue-router'
// const Login = '@/components/Login.vue'
// const Home = '@/components/Home.vue'
// const Message = '@/components/Message.vue'
// const Manage = '@/components/Manage.vue'
// const ActList = '@/components/ActList.vue'
// const RoleList = '@/components/RoleList.vue'
// const sellDept = '@/components/sellDept.vue'
// const designDept = '@/components/designDept.vue'
// const processDept = '@/components/processDept.vue'
// const planDept = '@/components/planDept.vue'
// const purchasingDept = '@/components/purchasingDept.vue'
// const productionDept = '@/components/productionDept.vue'
// const productionTeam = '@/components/productionTeam.vue'
// const testingDept = '@/components/testingDept.vue'
// const oldData = '@/components/oldData.vue'
// const sellDeptOrder = '@/components/sellDeptOrder.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: () => import('@/components/Login.vue') },
  { path: '/', redirect: '/login' },
  { path: '/Common@pdfViewer', component: () => import('@/components/common/pdfViewer.vue'), name: 'viewer' },  //这是文件预览路由
  {
    path: '/home',
    component: () => import('@/components/Home.vue'),
    redirect: '/message',
    children: [
      { path: '/message', component: () => import('@/components/Message.vue') },
      { path: '/AdminRoleAct@getAdminRoleActList', component: () => import('@/components/ActList.vue') },
      { path: '/Admin@getAdminList', component: () => import('@/components/Manage.vue') },
      { path: '/AdminRole@getAdminRoleList', component: () => import('@/components/RoleList.vue') },
      { path: '/Sale@getContractList', component: () => import('@/components/sellDept.vue') },
      { path: '/Design@getCommandList', component: () => import('@/components/designDept.vue') },
      { path: '/Craft@getCommandList', component: () => import('@/components/processDept.vue') },
      { path: '/Plan@getCommandList', component: () => import('@/components/planDept.vue') },
      { path: '/Buy@getCommandList', component: () => import('@/components/purchasingDept.vue') },
      { path: '/Produce@getCommandList', component: () => import('@/components/productionDept.vue') },
      { path: '/ProduceTeam@getDrawingList', component: () => import('@/components/productionTeam.vue') },
      { path: '/Quality@getCommandList', component: () => import('@/components/testingDept.vue') },
      { path: '/Old@getCommandList', component: () => import('@/components/oldData.vue') },
      { path: '/Sale@getCommandList', component: () => import('@/components/sellDeptOrder.vue') },
      { path: '/Warehouse@getHouseIntoList', component: () => import('@/components/InStore.vue') },
      { path: '/Warehouse@getHouseOutList', component: () => import('@/components/outStore.vue') },
      { path: '/Warehouse@getHouse2List', component: () => import('@/components/Library.vue') },
      { path: '/Warehouse@getHouseList', component: () => import('@/components/storeMaterial.vue') },

    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  //  获取token
  const tokenStr = window.localStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})

export default router
